import logo from './logo.svg';
import instaQr from './insta_qr.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <img src={logo} className="App-logo" alt="logo" />
          <div style={{ padding: 15 }} />
          <img src={instaQr} className="App-logo" alt="insta" />
        </div>
        <p>
          We are coming soon.<br />Meanwhile you can scan our instagram QR code and see photos of Jewellery there.
        </p>
      </header>
    </div>
  );
}

export default App;
